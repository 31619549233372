import React, { useCallback, useEffect, useState } from 'react';
import styles from './success.module.scss';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button';
import Socials from '../../components/socials';
import { getPdfReceipt } from '../../api/apiService';
import fileDownload from 'js-file-download';

const SuccessPage = () => {
  const { t } = useTranslation();
  const [documentId, setDocumentId] = useState('');

  const downloadReceipt = useCallback(async () => {
    if (documentId) {
      const receipt: any = await getPdfReceipt(documentId);
      fileDownload(receipt, 'receipt.pdf');
    }
  }, [documentId]);

  useEffect(() => {
    setDocumentId(sessionStorage.getItem('documentId') || '462d5a24-9525-4dd2-bd32-a7009e79c706');
  }, [documentId]);

  return (
    <div className={styles.success}>
      <div className={styles.top}>
        <div>
          <strong className="title">{t('success')}</strong>
        </div>
      </div>

      <div className={styles.main}>
        <strong>{t('you_successfully_signed_receipt')}</strong>
        <p>{t('press_button_to_download_receipt')}</p>
        <div className={styles.buttons}>
          <Button disabled={!documentId} className="full-width" onClick={downloadReceipt}>
            {t('download_receipt')}
          </Button>
          <Button
            className="full-width secondary"
            onClick={() => (window.location.href = 'https://stbunion.app.link/B0m5mKE8idb')}
          >
            {t('go_to_stb_app')}
          </Button>
        </div>

        <div className={styles.socials}>{t('visit_us_online')}</div>
        <Socials></Socials>
      </div>
    </div>
  );
};

export default SuccessPage;
