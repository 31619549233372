import React, { useCallback, useEffect, useState } from 'react';
import Button from '../../components/button';
import Header from '../../components/header';
import { useTranslation } from 'react-i18next';
import styles from './receipt.module.scss';
import { useNavigate } from 'react-router-dom';
import { Document, Page } from 'react-pdf';
import { getPdfReceipt } from '../../api/apiService';
import { pdfjs } from 'react-pdf';
import Spinner from '../../components/spinner';
import NoData from '../../components/no-data';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ReceiptPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [receipt, setReceipt] = useState<File>();
  const [spinner, setSpinner] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getReceipt = useCallback(async (documentId: string) => {
    setSpinner(true);
    const receipt = await getPdfReceipt(documentId);
    setReceipt(receipt);
    setSpinner(false);
  }, []);

  useEffect(() => {
    const documentId = sessionStorage.getItem('documentId');
    if (!documentId) {
      navigate('/error');
      return;
    }

    getReceipt(documentId).catch(() => {
      setSpinner(false);
      sessionStorage.removeItem('documentId');
      navigate('/error');
    });

    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [getReceipt]);

  return (
    <div>
      {spinner && <Spinner fullPage={true}></Spinner>}
      <Header></Header>
      {receipt && (
        <div className="container">
          <div className={styles.title}>{t('receipt')}</div>
          <div className={styles.receipt}>
            {receipt && (
              <Document file={receipt} noData={<NoData />} loading={<Spinner fullPage={true} />}>
                <Page
                  pageNumber={1}
                  width={windowWidth - 40}
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                />
              </Document>
            )}
          </div>
          <div className={styles.buttons}>
            <Button
              onClick={() => {
                navigate('/sign');
              }}
            >
              {t('sign_it')}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReceiptPage;
