import React, { useEffect, useState } from 'react';

import CanvasDraw from 'react-canvas-draw';
import Button from '../../components/button';
import Header from '../../components/header';
import styles from './sign-page.module.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { uploadImage } from '../../api/apiService';
import Spinner from '../../components/spinner';
import dataURItoBlob from '../../helpers/dataURItoBlob';

const SignPage = () => {
  let saveableCanvas;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [valid, setValid] = useState(false);
  const [touched, setTouched] = useState(false);

  const uploadSign = () => {
    setTouched(true);
    if (!valid) {
      return;
    }
    setSpinner(true);
    const payload = {
      id: sessionStorage.getItem('documentId'),
      type: 'SIGN',
      file: dataURItoBlob(saveableCanvas.getDataURL('png', '', 'transparent')),
    };

    uploadImage(payload)
      .then((data) => {
        setSpinner(false);
        navigate('/success');
      })
      .catch(({ message }) => {
        setSpinner(false);
        navigate('/failed');
        setErrorMessage(message || 'Failed to sign document');
      });
  };

  useEffect(() => {
    const documentId = sessionStorage.getItem('documentId');
    if (!documentId) {
      navigate('/error');
      return;
    }
  });

  return (
    <div className={styles.signPage}>
      {spinner && <Spinner fullPage={true}></Spinner>}
      <Header></Header>

      <div className="container">
        <section className="transaction-data"></section>

        <div className="sign">
          <label>{t('sign_here')}</label>

          <div className={`${styles.draw} ${!valid && touched && styles.error}`}>
            <CanvasDraw
              onChange={(data) => {
                setTouched(true);
                setValid(!!data.lines.length);
              }}
              ref={(canvasDraw) => (saveableCanvas = canvasDraw)}
              gridColor="#fff"
              canvasWidth={288}
              canvasHeight={150}
              lazyRadius={0}
              brushRadius={3}
            ></CanvasDraw>
          </div>
        </div>

        {errorMessage && <div className={styles.error}>{errorMessage}</div>}

        <div className={styles.buttons}>
          <div>
            <Button disabled={!valid && touched} className="full-width" onClick={uploadSign}>
              {t('save')}
            </Button>
          </div>
          <div className="div">
            <Button
              className="full-width cancel"
              onClick={() => {
                saveableCanvas.eraseAll();
              }}
            >
              <span className="material-symbols-outlined">close</span> {t('clear')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignPage;
